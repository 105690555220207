// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const baseUrlDev = 'https://prosperity-dev.onza.me/api';

export const environment = {
  production: false,
  CLIENT_SECRET_KEY: '17c5f50cdaa74f5dbf4d6a601d6c9017',
  API_BASE_URL: {
    userService: `${baseUrlDev}/v1/user-service/admin`,
    userServiceV2: `${baseUrlDev}/v2/user-service/admin`,
    groupService: `${baseUrlDev}/v1/group-service/admin`,
    reportService: `${baseUrlDev}/v1/scheduling-service/admin`,
    supportService: `${baseUrlDev}/v1/support-service`,
  },
  PUBNUBKey: {
    publishKey: 'pub-c-86d3e21e-ffa3-462d-89f8-0a25212be3fc',
    subscribeKey: 'sub-c-7a455150-cb50-11ea-b0f5-2a188b98e439',
    UUID: 'admin-1',
    channelPrefix: 'user_',
  },
  AzureSettings: {
    // Azure blob Constants
    sas: '',
    // Dev Storage Details
    storageAccount: 'wowmiproduction',
    containerName: 'loandepot',
    storageUrl: 'https://wowmiproduction.blob.core.windows.net/',
    storageFullUrl: 'https://wowmiproduction.blob.core.windows.net/loandepot/',
  },
};
